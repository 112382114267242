export const proxyHeaders = (headers): Record<string,string> => {
    const xHeaders = {}

    // proxy all x headers 
    Object.keys(headers).forEach((key) => {
        if (key.startsWith('x-')) {
            xHeaders[key] = headers[key]
        }
    })

    // proxy user agent header
    if (!xHeaders['x-user-agent'] && headers['user-agent']) {
        xHeaders['x-user-agent'] = headers['user-agent']
    }

    return xHeaders
}