import React from 'react'

import { useIsMobile } from '../../../../context/ux'

import ShoppingListButton from './shopping-list-button'
import MobileSearchButton from './mobile-search'
import ShopperLocationButton from './shopper-location'

const Buttons = () => {
    const isMobile = useIsMobile()
    return (
        <div className="buttons">
            {isMobile && <MobileSearchButton />}
            <ShopperLocationButton />
            <ShoppingListButton />
        </div>
    )
}

export default Buttons
