import { useForm } from '@zupr/form'
import { t } from '@zupr/i18n'
import React, { useCallback, useContext, useEffect, useState } from 'react'

import Badge from '../../../../shared/components/badge'
import Row, {
    CombinedFields,
    CombinedRows,
} from '../../../../shared/form/elements/row'

import Input from '../../../../shared/form/fields/input'
import Places from '../../../../shared/map/places'

import ShopperContext, { useShopperLocation } from '../../../../context/shopper'

import UxContext, { useIsMobile } from '../../../../context/ux'

import { ReactComponent as CheckIcon } from '../../../../../svg/checkmark.svg'
import { ReactComponent as UserLocationPin } from '../../../../../svg/pin.svg'
import { ReactComponent as QuestionIcon } from '../../../../../svg/questionmark.svg'

import DomainContext from '../../../../context/domain'
import { useShippingDetailsForm } from '../../../screens/checkout/hooks/shipping'
import GridBlockDetail from '../../grid/detail'
import Tray from '../../tray'

const ShopperLocationForm = ({ onSaved }) => {
    const { location, brand, type } = useContext(DomainContext)
    const { addNotification } = useContext(UxContext)

    const { updateShippingDetails } = useContext(ShopperContext)
    const { distance, setDistance, setZipcode, deleteLocation } =
        useShopperLocation()

    const form = useShippingDetailsForm()

    const distanceForm = useForm({
        pause: true,
        fields: {
            distance: {
                type: 'string',
            },
        },
        values: {
            distance,
        },
    })

    const zipcode = form.getField('zipcode')
    const number = form.getField('number')
    const distanceField = distanceForm.getField('distance')

    const handleSave = useCallback(() => {
        updateShippingDetails({
            zipcode: zipcode.value,
            number: number.value,
        })
        setZipcode(zipcode.value, number.value)
        setDistance(distanceField.value)
        onSaved()
        addNotification({
            level: 'success',
            message: t('Locatie opgeslagen'),
        })
    }, [
        updateShippingDetails,
        zipcode.value,
        number.value,
        setZipcode,
        setDistance,
        distanceField.value,
        onSaved,
        addNotification,
    ])

    const handleDelete = useCallback(() => {
        updateShippingDetails({
            zipcode: null,
            number: null,
            numberAddition: null,
        })
        deleteLocation()
    }, [updateShippingDetails, deleteLocation])

    return (
        <>
            <h3>{t('Your location')}</h3>
            <div className="tray-form">
                <p>
                    {type === 'location' &&
                        t(
                            'Weet direct of %{name} bij jou thuisbezorgt en wat de bezorgkosten zijn.',
                            'dutch',
                            {
                                name: brand || location?.title,
                            }
                        )}
                    {type === 'area' &&
                        t(
                            'Bekijk de winkels en producten bij jou in de buurt!',
                            'dutch'
                        )}
                </p>
                <Places />
                <GridBlockDetail>
                    <CombinedRows>
                        <Row field={zipcode}>
                            <Input field={zipcode} />
                        </Row>
                        <Row field={number}>
                            <Input field={number} />
                        </Row>
                    </CombinedRows>
                    <Row field={distanceField}>
                        <CombinedFields>
                            <Input
                                className="before-input"
                                field={distanceField}
                            />
                            <span className="after-input">{'km'}</span>
                        </CombinedFields>
                    </Row>
                    <button
                        className="btn btn-success btn-large"
                        onClick={handleSave}
                    >
                        {t('Save')}
                    </button>
                    <button className="btn btn-large" onClick={handleDelete}>
                        {t('Locatie verwijderen')}
                    </button>
                </GridBlockDetail>
            </div>
        </>
    )
}

const ShopperLocationButton = () => {
    const isMobile = useIsMobile()
    const [open, setOpen] = useState(false)
    const [hasUserLocation, setHasUserLocation] = useState<
        boolean | undefined
    >()
    const { shippingDetails } = useContext(ShopperContext)
    const { yourLocation } = useShopperLocation()

    const handleClose = useCallback(() => {
        setOpen(false)
    }, [])

    const handleOpen = useCallback(() => {
        setOpen(true)
    }, [])

    // move the bouncer into effect making it clientside only
    // as geolocating is client side only
    useEffect(() => {
        return setHasUserLocation(!!shippingDetails.zipcode)
    }, [shippingDetails.zipcode])

    return (
        <React.Fragment key={yourLocation}>
            <button
                className="inline"
                aria-label={yourLocation}
                onClick={handleOpen}
            >
                <span>
                    <UserLocationPin />
                    {typeof hasUserLocation === 'boolean' && (
                        <Badge small warning bounce={!hasUserLocation}>
                            {!!hasUserLocation && <CheckIcon />}
                            {!hasUserLocation && <QuestionIcon />}
                        </Badge>
                    )}
                </span>
                {!isMobile && <span>{yourLocation}</span>}
            </button>
            <Tray sidebar onClose={handleClose} open={open} right>
                <ShopperLocationForm onSaved={handleClose} key={yourLocation} />
            </Tray>
        </React.Fragment>
    )
}

export default ShopperLocationButton
