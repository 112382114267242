import { useForm } from '@zupr/form'
import { canDeliverQuery } from '@zupr/queries/order'
import { useCallback, useContext, useMemo } from 'react'
import { useQuery } from 'urql'

import { Location } from '@zupr/types/fo'
import { Form } from '@zupr/types/form'
import { CanDeliverType, Query, QueryCanDeliverArgs } from '@zupr/types/graphql'

import AreaContext from '../../../../context/domain'
import ShippingDetailsContext, {
    useUpdateShippingDetails,
} from '../../../../context/shopper'

export const usePersonalDetailsForm = () => {
    const { shippingDetails } = useContext(ShippingDetailsContext)

    const form = useForm({
        pause: true,
        values: {
            ...shippingDetails,
            newsletter: false,
            terms: false,
            personalConfirmed: false,
        },
        fields: {
            name: {
                type: 'string',
                required: true,
                label: 'Name',
                max_length: 255,
            },
            phone: {
                type: 'string',
                label: 'Phonenumber',
                required: true,
            },
            email: {
                type: 'email',
                label: 'Email',
                required: true,
                max_length: 300,
            },
            emailVerify: {
                type: 'email',
                label: 'E-mailadres herhalen',
                placeholder: 'Herhaal je e-mailadres',
                required: true,
                max_length: 300,
            },
            newsletter: {
                type: 'boolean',
                label: 'Newsletter',
            },
            terms: {
                type: 'confirm',
                label: 'Terms',
                required: true,
            },
            vatReceipt: {
                type: 'boolean',
                label: 'Receipt',
            },
            invoice: {
                type: 'boolean',
                label: 'Billing information',
            },
        },
    })

    return form
}

export const useShippingDetailsForm = () => {
    const { shippingDetails } = useContext(ShippingDetailsContext)

    const form = useForm({
        pause: true,
        values: {
            ...shippingDetails,
            vatReceipt: false,
            newsletter: false,
            terms: false,
            deliveryAddressConfirmed: false,
            invoiceAddressConfirmed: false,
        },
        fields: {
            name: {
                type: 'string',
                required: true,
                label: 'Name',
                max_length: 255,
            },
            company: {
                type: 'string',
                label: 'Company',
                max_length: 255,
            },
            zipcode: {
                type: 'string',
                label: 'Zipcode',
                required: true,
                max_length: 20,
                regex: /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i,
                placeholder: '9700 AA',
            },
            address: {
                type: 'string',
                label: 'Address',
                required: true,
                max_length: 255,
                regex: /.*[a-zA-Z].*/,
            },
            city: {
                type: 'string',
                label: 'City',
                required: true,
                max_length: 255,
            },
            number: {
                type: 'string',
                label: 'House number',
                required: true,
                max_length: 20,
                regex: /^[0-9].*/,
                placeholder: '123',
            },
            deliverInstructions: {
                type: 'string',
                label: 'Opmerking voor de bezorger',
                placeholder: 'Schrijf hier een opmerking voor de bezorger...',
            },
            numberAddition: {
                type: 'string',
                label: 'Addition',
                max_length: 10,
            },
            invoiceName: {
                type: 'string',
                label: 'Name',
                max_length: 255,
            },
            invoiceCompany: {
                type: 'string',
                label: 'Company',
                max_length: 255,
            },
            invoiceZipcode: {
                type: 'string',
                label: 'Zipcode',
                max_length: 20,
            },
            invoiceAddress: {
                type: 'string',
                label: 'Address',
                max_length: 255,
            },
            invoiceCity: {
                type: 'string',
                label: 'City',
                max_length: 255,
            },
            invoiceNumber: {
                type: 'string',
                label: 'House number',
                max_length: 20,
            },
            invoiceNumberAddition: {
                type: 'string',
                label: 'Addition',
                max_length: 10,
            },
            phone: {
                type: 'string',
                label: 'Phonenumber',
                required: true,
            },
            email: {
                type: 'email',
                label: 'Email',
                required: true,
                max_length: 300,
            },
            emailVerify: {
                type: 'email',
                label: 'E-mailadres herhalen',
                placeholder: 'Herhaal je e-mailadres',
                required: true,
                max_length: 300,
            },
            newsletter: {
                type: 'boolean',
                label: 'Newsletter',
            },
            terms: {
                type: 'confirm',
                label: 'Terms',
                required: true,
            },
            vatReceipt: {
                type: 'boolean',
                label: 'Receipt',
            },
            invoice: {
                type: 'boolean',
                label: 'Billing information',
            },
        },
    })

    return form
}

interface UseShippingProps {
    locationId: Location['id']
    pause?: boolean
}

const useShipping = ({
    locationId,
    pause,
}: UseShippingProps): [Form, CanDeliverType, any] => {
    const { shoppingAreaSlug } = useContext(AreaContext)

    const form = useShippingDetailsForm()
    const updateShippingDetails = useUpdateShippingDetails()

    const zipcode = form.getField('zipcode')
    const number = form.getField('number')
    const numberAddition = form.getField('numberAddition')

    const [canDeliverResult, executeCanDeliverRequest] = useQuery<
        Query,
        QueryCanDeliverArgs
    >({
        query: canDeliverQuery,
        pause: !number.value || !zipcode.value || pause,
        variables: {
            shoppingAreaSlug,
            locationId,
            deliverAddress: {
                zipcode: zipcode.value,
                number: number.value,
                numberAddition: numberAddition.value,
            },
        },
    })

    const canDeliver = useMemo(() => {
        if (
            zipcode.value !==
            canDeliverResult.operation?.variables?.deliverAddress?.zipcode
        )
            return undefined
        return canDeliverResult.data?.canDeliver
    }, [
        canDeliverResult.data?.canDeliver,
        canDeliverResult.operation?.variables?.deliverAddress?.zipcode,
        zipcode.value,
    ]) as CanDeliverType

    const checkZipcode = useCallback(() => {
        updateShippingDetails(form.getBody())
        executeCanDeliverRequest({ requestPolicy: 'network-only' })
    }, [executeCanDeliverRequest, form, updateShippingDetails])

    return useMemo(
        () => [form, canDeliver, checkZipcode],
        [form, canDeliver, checkZipcode]
    )
}

export default useShipping
