import classNames from 'classnames'
import React from 'react'

import '../../../../scss/react/frontoffice/grid-page.scss'

interface GridBlockDetailProps {
    className?: string
    children: React.ReactNode
    center?: boolean
}

const GridBlockDetail = ({
    className,
    children,
    center,
}: GridBlockDetailProps) => (
    <div
        className={classNames('grid-block-detail', className, {
            'grid-block-detail-center': center,
        })}
    >
        {children}
    </div>
)

export default GridBlockDetail
